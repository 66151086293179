.navbarBg {
  background-color: #29cff5;
  &-text {
    color: red;
    font-size: 1.5em;
    float: right;
  }
  
}
.navbarBgDashboard {
  background-color: #29cff5;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 70px;
  transition: all 0.3s ease-in-out 0s;
}
.navbar {
  &-btn {
    color: $white;
    &:focus,
    &:active,
    &:focus:active {
      outline-color: $computas-blue;
    }
  }

  &-brand {
    padding: 0;
    color: $white;
  }

  &-brand-focus {
    outline-color: #29cff5;
  }

  &-logo {
    font-weight: 600;
    font-size: 1.45rem;
    letter-spacing: 2px;
    outline-color: $computas-blue;

    .nav > li > a[id="pendingRequestsDropdown"] {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
