body {
	padding-top: 75px;
	background-color: #29cff5 !important;
}

*:focus {
	outline: none !important;
}

p {
	margin-bottom: 0rem;
}

.table {
	margin-bottom: 0px;
}

.headerBgBlue {
	background-color: #29cff5;
}

.bgLarge {
	position: fixed;
	bottom: 0;
	width: 45%;
	right: 0;
}

.bgSmall {
	position: fixed;
	bottom: 0;
	z-index: 99;
	width: 100%;
	right: 0;
}
.overlay {
	z-index: 999;
}

.validationMessage {
	text-align: center;
	font-family: $open-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	color: #fff !important;
}

.text-login {
	font-family: $open-sans;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 13px;
	text-align: right;
	color: #ffffff;
	margin-top: 5%;
}

.headings-title {
	font-size: 18px;
	line-height: 26px;
	font-family: $open-sans;
	color: $computas-dark-blue;
}

.login-parag {
	background-color: #29cff5;
	align-content: flex-end;
	font-family: $open-sans;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 25px;
	color: #003459;
	z-index: 999;
	a {
		color: none !important;
		text-decoration: underline;
		background-color: transparent;
	}
	a:hover {
		text-decoration: underline;
	}
}

.mxWidth {
	max-width: 450px;
	justify-content: center;
}

.dropzone {
	background-color: #fff;
	border: 1px solid #29cff5;
	box-sizing: border-box;
	border-radius: 8px;
	color: #707070;
	cursor: pointer;
	&.drop-active {
		border-color: $success;
	}
	&.drop-reject {
		border-color: $danger;
	}
}

.dropzoneDisabled {
	background-color: #f5f5f5;
	border: 1px solid #eaeaea;
	box-sizing: border-box;
	border-radius: 8px;
	color: #707070;
	cursor: pointer;
	&.drop-active {
		border-color: $success;
	}
	&.drop-reject {
		border-color: $danger;
	}
}

.label-text {
	color: #003459;
	font-size: 13px;
	line-height: 16px;
	font-family: $open-sans;
	font-style: normal;
	font-weight: 600;
	display: flex;
	align-items: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.inputActive {
	background-color: #fff;
	border: 1px solid #29cff5;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 4px;
	z-index: 999;
	&.drop-active {
		border-color: $success;
	}
	&.drop-reject {
		border-color: $danger;
	}
}

.inputDisabled {
	background-color: transparent;
	border: none;
	box-sizing: border-box;
	border-radius: 8px;
	&.drop-active {
		border-color: $success;
	}
	&.drop-reject {
		border-color: $danger;
	}
}

.inputInvalid {
	background-color: #fff;
	border: 1px solid #ff5f63;
	box-sizing: border-box;
	border-radius: 8px;
	padding: 4px;
	z-index: 999;
	&.drop-active {
		border-color: $success;
	}
	&.drop-reject {
		border-color: $danger;
	}
}

.input-text {
	color: #003459;
	font-size: 16px;
	line-height: 25px;
	font-family: $open-sans;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
}

.form-dashboard {
	overflow: hidden;
}

.form-signin {
	padding: 15px;
	margin: auto;
	position: fixed;
	left: 50%;
	top: 40%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
	margin-bottom: 10px;
}
.form-signin .checkbox {
	font-weight: normal;
}
.form-signin .form-control {
	position: relative;
	height: auto;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 10px;
	font-size: 16px;
}
.form-signin .form-control:focus {
	z-index: 2;
}

.form-signin input[type="email"] {
	margin-bottom: -1px;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
	margin-bottom: 10px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.form-control:read-only {
	border: none;
	border-color: transparent;
	background-color: transparent;
	color: #003459;
	font-size: 16px;
	line-height: 25px;
	font-family: $open-sans;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
}
.form-control:focus {
	border: none;
	border-color: transparent;
	background-color: transparent;
	color: #003459;
	font-size: 16px;
	line-height: 25px;
	font-family: $open-sans;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	box-shadow: none !important;
}

.form-control:active {
	border: none;
	border-color: transparent;
	background-color: transparent;
	color: #003459;
	font-size: 16px;
	line-height: 25px;
	font-family: $open-sans;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
}

.form-control {
	border: none;
	border-color: transparent;
	background-color: transparent;
	color: #003459;
	font-size: 16px;
	line-height: 25px;
	font-family: $open-sans;
	font-style: normal;
	font-weight: normal;
	display: flex;
	align-items: center;
	border: none;
}

.text-infoicon {
	font-family: $open-sans;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 18px;
	text-align: center;
	color: #003459;
	margin-top: 5%;
}

.underline {
	text-decoration: underline !important;
}
.validation-error {
	color: $computas-red;
}

.containerLatestActivity {
	width: 400px;
	margin: 10px;
}

.elementLatestActivity {
	background-color: plum;
	margin: 10px 2px;
	border-radius: 15px;
}

.badge-notify {
	background: $computas-red;
	position: relative;
	top: -20px;
	left: -35px;
}

#bg {
	position: fixed;
	top: 0;
	left: 0;

	/* Preserve aspet ratio */
	min-width: 100%;
	min-height: 100%;
}

.icon-wrapper {
	position: relative;
	float: left;
}

.icon-wrapper i {
	width: 16px;
}

.info-section {
	border-left-style: solid;
	border-left-color: $computas-green;
}

.info-icon {
	color: $computas-blue;
}

.loginBg {
	height: 103vh;
	min-height: 200px;
	background-color: $computas-blue;
	img {
		display: inline-block;
		position: absolute;
		//top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
	}
	p:first-child {
		text-align: center;
		color: white;
		font-size: 2.2em;
		margin-top: 20px;
		padding-top: 4px;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
		font-weight: bold;
	}
}

.bankIDButton {
	border: 1px solid white;
	border-radius: 30px;
	background-color: transparent;
	font-weight: bold;
	padding: 10px;
	align-content: center;
	align-items: center;
	align-self: center;
	// font-family: "Liberation Mono";
	text-align: center;
	color: $computas-dark-blue;
}
// .flex-root {
//   display: flow-root;
// }

// .MuiGrid-container {
//   width: 100%;
//   display: flow-root !important;
//   flex-wrap: wrap !important;
//   box-sizing: border-box;
// }

.loadingWrapper {
	background-color: "transparent";
	box-shadow: "none";
	position: "relative";
	margin: auto;
	display: flex;
	justify-content: center;
	flex-direction: row;
}

.maxWidth {
	max-width: 750px;
}

.sticky {
	position: sticky;
	top: 0;
	z-index: 1000;
}

.wrapperDashboard {
	background-color: #29cff5;
	box-shadow: "none";
	height: 50vh;
	z-index: 9999;
}

.marginTop {
	margin-top: 6%;
}

.dashboardBg {
	background-color: aquamarine;
}

.icons-chevron {
	color: $computas-blue;
	//margin-right: 0px;
	//margin-left: -30px;
}

.icons-chevronold {
	color: $computas-blue;
	margin-right: 30px;
	margin-left: -30px;
}

.text-infoicon {
	color: $computas-dark-blue;
	font-size: 1.2em;
	font-family: "Consolas";
	font-style: normal;
	font-variant: normal;
}

.info-section2 {
	border-left-style: solid;
	border-left-color: $computas-red;
}

.dateContact {
	margin-left: 10%;
	&-text {
		color: $computas-dark-blue;
		font-size: 1.5em;
	}
}

.info-icon2 {
	color: $computas-blue;
}
.dashboardMiddle {
	height: 50vh;
	min-height: 150px;
	background-color: $computas-yellow;

	p:first-child {
		text-align: center;
		color: white;
		font-size: 2em;
		margin-top: 100px;
		padding-top: 4px;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
		font-weight: bold;
	}
	p:last-child {
		text-align: justify;
		color: $computas-dark-blue;
		font-size: 1.2em;
		//margin-top: 120px;
		//padding-top: 4px;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
		//font-weight: bold;
	}
}

.dashboardDescription {
	height: 50vh;
	min-height: 150px;
	background-color: $computas-yellow;
	// background-image: url("../img/girl.jpg");
	// background-size: cover;
	// background-position: center;
	// background-position-y: -10px; //in case the top of the image is cropped on landscape
	// background-repeat: no-repeat;
	//background-color: $computas-blue;
	text-align: justify;
	color: $computas-dark-blue;
}

.paragraph {
	margin: 0;
	position: absolute;
	top: 70%;
	left: 50%;
	-ms-transform: translate(-50%, 90%);
	transform: translate(-50%, 90%);
}

.paragraphTitle {
	margin: 0;
	position: absolute;
	top: 10%;
	left: 50%;
	-ms-transform: translate(-50%, 90%);
	transform: translate(-50%, 90%);
	font-size: 2em;
}

.paragraphDescription {
	margin: 0;
	position: absolute;
	top: 10%;
	left: 50%;
	-ms-transform: translate(-50%, 90%);
	transform: translate(-50%, 90%);
	font-size: 2em;
}

.dashboard-text {
	color: $computas-dark-blue;
	font-size: 1.2em;
	//font-family: "Consolas";
	font-style: normal;
	font-variant: normal;
	font-weight: bold;
	letter-spacing: normal;
}

.dashboard-text-footer {
	color: $computas-dark-blue;
	font-size: 1.2em;
	//font-family: "Consolas";
	font-style: normal;
	font-variant: normal;
	font-weight: bold;
	letter-spacing: normal;
	text-align: center;
}

.dashboarButton {
	border: 1px solid $computas-dark-blue;
	border-radius: 30px;
	background-color: transparent;
	font-weight: bold;
	padding: 20px;
	align-content: center;
	align-items: center;
	align-self: center;
	text-align: center;
}

.footerBg {
	background-color: $computas-dark-blue;
}

.footer {
	background-color: $computas-dark-blue;
	// margin-left: 50%;
	// margin-right: 50%;
	justify-content: center;
	align-self: center;
	align-items: center;
	// img {
	//   display:block;
	//   margin:auto;
	// }
}

.item {
	flex: 0 0 15%;
}

.centerImage {
	//display: inline-block;
	position: absolute;
	//top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
}
.icon-wrapper .badge {
	background: $computas-red;
	width: auto;
	height: auto;
	margin: 0;
	border-radius: 50%;
	position: absolute;
	top: -8px;
	right: -6px;
	padding: 3px 4px;
	color: #fff;
	font-size: 0.7em;
	z-index: 1;
}

.approvalButtons {
	margin: 17px;
}
.approvalButtons button {
	width: 80px;
}

.Select-control {
	border-radius: 0px;
}

.divRequestListButtonGroup {
	margin-top: 10px;
	padding-bottom: 50px !important;
	padding-top: 10px;
}

.divRequestListButtonGroup button {
	width: 130px;
	margin-left: 5px;
	padding-bottom: 7px;
}

.divSelectYearRequestListButtonGroup {
	width: 85px;
}

.radioButtonsRequestListButtonGroup {
	margin-left: 5px;
	margin-top: -4px;
	margin-right: 5px;
}

.radioButtonsRequestListButtonGroup .radio {
	margin-top: 0px;
	margin-bottom: 5px;
}

.radioButtonsRequestListButtonGroup .radio label {
	font-size: 11px;
	min-height: 15px;
}

.radioButtonsRequestListButtonGroup .radio input[type="radio"] {
	margin-left: -15px;
	margin-top: 1px;
}

.textAreaRejectRequest {
	resize: vertical;
	max-height: 400px;
}

.leavePlanCalendar {
	.cell {
		margin: 10px;
		padding: 4px 7px;
		max-width: 38px;
		max-height: 38px;
		width: 100%;
		height: 100%;
		font-size: 1.2rem;
	}
}

.expandedTable {
	margin-left: 45px;

	.listGroup {
		padding: 5px;
		width: 90%;
	}
}

.divTimesheetFilters {
	margin-top: 20px;
	padding-bottom: 50px;
}

.divTimesheetFilters .divMonth {
	width: 100%;
	max-width: 125px;
	float: right;
}
.divTimesheetFilters .divYear {
	width: 100%;
	max-width: 90px;
	float: right;
	margin-left: 5px;
}

table {
	background-color: white;
}

.td-column-weekend-publicHoliday {
	background-color: $hrm-table-td-column-grey-header;
}

.td-column-wfh {
	background-color: $computas-yellow;
}

.td-column-travel {
	background-color: $computas-green;
}

.legendIcon {
	color: gray;
}

.formOverlapping {
	border-top-style: inset;
	border-width: thin;
	padding-top: 10px;
}

.dropdown-menu > li > a:focus {
	outline-color: $computas-green;
}

.__react_component_tooltip.type-info {
	background-color: $computas-green;
}

.__react_component_tooltip.type-error {
	background-color: $computas-red;
}

.__react_component_tooltip.type-warning {
	background-color: $computas-yellow;
}

.__react_component_tooltip.type-info.place-top::after {
	border-top-color: $computas-green;
}

.calendarSize:focus {
	outline-color: $computas-green;
}

.requestImageStyle {
	width: 30px;
	margin-right: 10px;
}

.officeLeaveTitleTable {
	background-color: $hrm-table-td-column-grey-header;
	padding: 5px 10px;
	font-weight: bolder;
}

.cursor-pointer {
	cursor: pointer;
}

// .centerGroup {
//   //height: 100%;
//   // display: center;
//   // justify-content: center;
//   // align-items: center;
//   // text-align: center;
//   //overflow: hidden;
// }

// .userIcon {

// }

.logo-img {
	height: 30px;
	background-repeat: no-repeat;
	object-fit: cover;
	float: left;
	margin-right: 20px;
	z-index: 100;
}

.computasLogo-img {
	height: 60px;
	max-height: 100px;
	align-content: center;
	//background-repeat: no-repeat;
	//object-fit: cover;
	//float: left;
	//margin-right: 10px;
	z-index: 100;
}

.logoComputas {
	z-index: -1;
}

.button {
	// background-color: green; /* Green */
	// border: none;
	// text-decoration: none;
	// display: inline-block;
	// margin: 4px 2px;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	.googleBtn {
		background-color: transparent;
		color: black;
		font-size: 16px;
		border: solid 1px $computas-dark-blue;
		cursor: pointer;
		text-align: center;
	}
}
.listGroups {
	.list-group-item {
		border: 1px solid $computas-dark-blue;
		border-radius: 25px;
	}
}

.bestillPc {
	margin-left: 10px;
	p:first-child {
		color: $computas-dark-blue;
		font-size: 1.5em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
	}
	p:last-child {
		color: $computas-yellow;
		font-size: 1em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
	}
}

.bestillTelefon {
	margin-left: 10px;
	p:first-child {
		color: $computas-dark-blue;
		font-size: 1.5em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
		//word-spacing: 1.1em;
	}
	p:last-child {
		color: $computas-blue;
		font-size: 1em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
	}
}

.bestillNummer {
	margin-left: 10px;
	p:first-child {
		color: $computas-dark-blue;
		font-size: 1.5em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
	}
	p:last-child {
		color: $computas-red;
		font-size: 1em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
	}
}

.paragraphListTitle {
	color: $computas-dark-blue;
	font-size: 1.5em;
	font-family: "Consolas";
	font-style: normal;
	font-variant: normal;
}

.paragraphListDescription {
	color: $computas-green;
	font-size: 1em;
	font-family: "Consolas";
	font-style: normal;
	font-variant: normal;
}

.dashboarComponent {
	margin-left: 10px;

	p {
		color: $computas-dark-blue;
		font-size: 1.3em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
		text-align: center;
	}
}

.bestillComponent {
	margin-left: 10px;

	p {
		color: $computas-dark-blue;
		font-size: 1.2em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
	}
}
.bestillYellow {
	margin: auto;
	//height: 15vh;
	//min-height: 50px;
	background-color: $computas-yellow;
	p:first-child {
		color: $computas-dark-blue;
		font-size: 1.2em;
		font-family: "Consolas";
		font-style: normal;
		font-variant: normal;
	}
}

.bestillButton {
	border: 1px solid none;
	border-radius: 30px;
	background-color: $hrm-table-td-column-light-grey;
	font-weight: bold;
	padding: 20px;
	align-content: center;
	align-items: center;
	align-self: center;
	text-align: center;
}

.btn {
	&-login {
		background-color: $computas-light-green;
		border: 1px solid $computas-green;
		justify-content: flex-start;
		display: flex;
		font-size: 15px;

		&:hover,
		&:active:focus,
		&:focus {
			background-color: $computas-green;
			border: 1px solid $computas-green;
			outline-color: $computas-green;
		}

		&-text {
			padding-top: 4px;
		}
	}

	&-google {
		background-color: white;
		border: 1px solid $computas-green;

		&:hover,
		&:active:focus,
		&:focus {
			background-color: white;
		}
	}

	&-link,
	&-link:hover {
		color: $gray-900;
	}
}

.btn {
	&-login {
		background-color: $computas-light-green;
		border: 1px solid $computas-green;
		justify-content: flex-start;
		display: flex;
		font-size: 15px;

		&:hover,
		&:active:focus,
		&:focus {
			background-color: $computas-green;
			border: 1px solid $computas-green;
			outline-color: $computas-green;
		}

		&-text {
			padding-top: 4px;
			border: 3px solid #767676;
		}
	}

	&-google {
		background-color: white;
		border: 1px solid $computas-green;
		font-size: 1rem;
		color: $computas-dark-blue;

		&:hover,
		&:active:focus,
		&:focus {
			background-color: white;
		}
	}

	&-link,
	&-link:hover {
		color: $gray-900;
	}
}

.wrapper {
	background-color: transparent;
	box-shadow: none;
}

.avatarBG {
	margin-left: 50%;
	margin-right: 50%;
	align-items: center;
	align-self: center;
	justify-content: center;
}

.avatarText {
	text-align: center;
	color: $computas-dark-blue;
	font-weight: bold;
	p {
		font-weight: normal;
	}
}

.userGuide {
	width: 100%;
	height: calc(100vh - 80px);
}

@media (min-width: 1200px) {
	.container {
		width: 1500px;
	}
}

.totalVacationColumn {
	background-color: lightgray;
	color: black;
}

@media (min-width: 1500px) {
	.container {
		width: 1300px;
	}
}

.timesheetTotalsGrid .table-bordered > tbody > tr:nth-last-child(1) > td {
	border: none;

	&:nth-last-child(1),
	&:nth-last-child(2),
	&:nth-last-child(3),
	&:nth-last-child(4) {
		border-left: 1px solid rgb(221, 221, 221);
		background-color: rgb(217, 217, 217);
	}
}

.timesheetGrid {
	td,
	th {
		text-overflow: unset !important;
	}
}

@media (min-width: 1200px) {
	.container {
		width: 1400px;
	}
}

.header-margin-tab {
	margin-top: 50px;
	margin-bottom: 50px;
}

.modal .container {
	width: auto;
}

.horizontalLine {
	margin-bottom: 0.2em;
	margin-top: 0.2em;
	display: block;
}

.modal-title {
	font-size: 1.56rem;
}

.administrationPageItem:focus {
	outline-color: unset;
	outline: none;
}

.pricesAndCostsGrid .react-bs-table table td {
	text-overflow: unset;
}

.errorDisplay {
	text-align: center;
	height: calc(100vh - 200px);
	display: flex;
	justify-content: space-evenly;
	color: gray;
	align-items: center;
	margin-top: 20px;
	line-height: 400%;

	.errorMessage {
		align-self: center;
		justify-content: center;
		opacity: 0.5;
	}

	.errorImage {
		width: 190px;
		height: 200px;
		justify-content: center;
	}
}

.separator-container {
	padding: 25px 0;

	.separator-text {
		position: relative;
		top: -8px;
		padding: 0 20px;
		background-color: $computas-blue;
		font-size: 1.2em;
		color: white;
	}

	.separator:before {
		display: block;
		content: "";
		border-bottom: 1px solid #e5e5e5;
	}

	.separator {
		font-size: 12px;
		line-height: 16px;
		color: #767676;
		text-align: center;
	}
}

.dashboard {
	.snow {
		position: absolute;
		top: -90px;
		left: 0;
		width: 100%;
		height: 100vh;
	}
	i.snowflake,
	i.snowflake:after,
	i.snowflake:before {
		background: gray;
	}

	i.snowflake {
		display: inline-block;
		-webkit-animation: snowflakes 3s linear 2s 20;
		-moz-animation: snowflakes 3s linear 2s 20;
		position: relative;
		z-index: 1000;
		top: -200px;
	}

	i.snowflake:after,
	i.snowflake:before {
		height: 100%;
		width: 100%;
		content: ".";
		position: absolute;
		top: 0px;
		left: 0px;
		-webkit-transform: rotate(120deg);
	}
	i.snowflake:before {
		-webkit-transform: rotate(240deg);
	}

	@-webkit-keyframes snowflakes {
		0% {
			-webkit-transform: translate3d(0, 0, 0) rotate(0deg) scale(0.6);
		}
		100% {
			-webkit-transform: translate3d(15px, 1200px, 0px) rotate(360deg) scale(0.6);
		}
	}

	i.snowflake:nth-child(3n) {
		width: 16px;
		height: 4px;
		background: $computas-green;
		-webkit-animation-duration: 14s;
		-webkit-animation-iteration-count: 30;
		-webkit-transform-origin: right -45px;

		&::before,
		&::after {
			background: $computas-green;
		}
	}

	i.snowflake:nth-child(3n + 1) {
		width: 24px;
		height: 6px;
		background: $computas-dark-blue;
		-webkit-animation-duration: 10s;
		-webkit-animation-iteration-count: 45;
		-webkit-transform-origin: right -70px;

		&::before,
		&::after {
			background: $computas-dark-blue;
		}
	}

	i.snowflake:nth-child(3n + 2) {
		width: 32px;
		height: 8px;
		background: $computas-light-green;
		-webkit-animation-duration: 12s;
		-webkit-animation-iteration-count: 60;
		-webkit-transform-origin: right -115px;

		&::before,
		&::after {
			background: $computas-light-green;
		}
	}

	/* different delays so they don't all start at the same time */
	i.snowflake:nth-child(7n) {
		opacity: 0.3;
		-webkit-animation-delay: 0s;
		-webkit-animation-timing-function: ease-in;
	}
	i.snowflake:nth-child(7n + 1) {
		opacity: 0.4;
		-webkit-animation-delay: 1s;
		-webkit-animation-timing-function: ease-out;
	}
	i.snowflake:nth-child(7n + 2) {
		opacity: 0.5;
		-webkit-animation-delay: 1.5s;
		-webkit-animation-timing-function: linear;
	}
	i.snowflake:nth-child(7n + 3) {
		opacity: 0.6;
		-webkit-animation-delay: 2s;
		-webkit-animation-timing-function: ease-in;
	}
	i.snowflake:nth-child(7n + 4) {
		opacity: 0.7;
		-webkit-animation-delay: 2.5s;
		-webkit-animation-timing-function: linear;
	}
	i.snowflake:nth-child(7n + 5) {
		opacity: 0.8;
		-webkit-animation-delay: 3s;
		-webkit-animation-timing-function: ease-out;
	}
	i.snowflake:nth-child(7n + 6) {
		opacity: 0.9;
		-webkit-animation-delay: 3.5s;
		-webkit-animation-timing-function: ease-in;
	}
}

.react-bs-table-container {
	a {
		color: $gray-900;
	}

	.react-bs-table-bordered {
		border-radius: 0;
	}
}

.legend-allocations {
	font-weight: bold;

	.legend {
		padding: 3px;

		&Blue {
			background-color: $computas-blue;
			color: white;
		}
		&Gray {
			background-color: $computas-green;
		}
		&Red {
			background-color: $computas-red;
			color: white;
		}
	}
}

.percentageTable {
	.react-bs-table table tbody td,
	.react-bs-table .react-bs-container-header table th {
		text-overflow: unset;
		overflow: visible;

		.select-filter.placeholder-selected {
			padding: 0;
			color: inherit;
			font-style: normal;
			font-weight: bold;
		}

		&.default-focus-cell {
			outline: 2px solid $computas-green !important;
		}
	}

	tr {
		td:last-child.isNewClassName {
			background-color: inherit;
		}
		td {
			.form-control,
			input {
				height: 22px;
				padding: 0;
				padding-left: 6px;
				padding-right: 6px;
			}
		}
	}

	.availableCapacityEqual {
		background-color: $computas-green;
	}

	.isNewClassName {
		background-color: rgba(254, 213, 70, 0.8);
	}
}

.allocationsGrid {
	.table th,
	.table td {
		padding: 0.5rem;
		text-overflow: unset;
		vertical-align: top;
		border-top: none;
	}
}

.allocations-projects-vacations {
	background-color: $hrm-table-td-column-light-grey;
}

.allocations-projects-availablecapacity {
	background-color: lightgray;
}

.btn-group {
	> button.btn.allocationsButtons,
	> div {
		margin-left: 4px;
		float: left;

		&:first-child {
			margin-left: 0;
		}
	}
}

a:focus,
a:hover {
	text-decoration: none;
}

.allocationDetails-available {
	background-color: $hrm-table-td-column-medium-grey;
}

.allocationDetailsTable {
	margin-bottom: 50px;

	tr {
		td {
			.form-control,
			input {
				height: auto;
				padding: 0 0.2rem;
			}
		}
	}
}

.availableCapacityHigh {
	background-color: $computas-blue;
	color: white;
}

.availableCapacityLow {
	background-color: $computas-red;
	color: white;
}

.react-timefield {
	min-width: 70px;
}

.invisible {
	> .table-data-content {
		display: none;
	}
}

.navbar-expand {
	flex-wrap: wrap;
}

.mobile {
	flex-grow: 1;
}

.divSelectEmployeeRequestListButtonGroup {
	min-width: 250px;
}

//mobile-responsiveness

@media screen and (max-width: 765px) {
	.divSelectEmployeeRequestListButtonGroup {
		min-width: 135px;
	}
	.button-responsive {
		margin-right: 0 !important;
	}
	.allocationDetailsTable {
		overflow-x: scroll;
		.react-bootstrap-table {
			min-width: 1600px;
		}
	}

	.responsiveGrid {
		overflow-x: scroll;

		.react-bootstrap-table {
			min-width: 1200px;
		}
	}

	.responsiveGrid-sm {
		overflow-x: scroll;

		.react-bootstrap-table {
			min-width: 800px;
		}
	}

	.responsive-page {
		overflow: hidden;
	}

	.divLogWorkFilter {
		text-align: center;
	}

	.administration-title {
		margin-top: 30px !important;
	}

	.confirmPending {
		color: white;
		&:hover {
			color: white;
		}
	}

	.formResponsive {
		margin-right: 15px;
	}

	.navbar-nav .dropdown-menu {
		position: absolute;
	}
}

.pdf-viewer-list {
	display: none;
}

.viewer-controls-button {
	display: none;
}
